<template>
	<div>
		<CRow>
			<CCol>
				<CCard>
					<CCardHeader>
						My Interest
					</CCardHeader>
					<CCardBody>
						<CRow>
							<div class="position-relative table-responsive col-md-12">
								<table class="table">
									<thead>
										<tr>
											<th>Product</th>
											<th>Price</th>
											<th class="text-center">Expired</th>
											<th class="text-center">Status</th>
											<th>Win Way</th>
											<th>Winner</th>
										</tr>
									</thead>
									<tbody class="position-relative">
										<tr v-for="(int, i) in interests" :key="i">
											<td>{{ int.product.name }}</td>
											<td class="text-right">
												{{
													int.product.price
														? int.product.price.toLocaleString()
														: "0"
												}}
											</td>
											<td class="text-center">
												{{
													new Date(int.product.expired * 1000)
														.toISOString()
														.split("T")[0]
												}}
											</td>
											<td class="text-center">
												<img
													width="23px;"
													:src="image(int.status)"
													:alt="int.status"
													srcset=""
												/>
											</td>
											<td>{{ int.product.winWay }}</td>
											<td>{{ "XXX" }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</CRow>
					</CCardBody>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>

<script>
export default {
	data() {
		return {
			interests: [],
		};
	},
	mounted() {
		this.getInterest();
	},
	methods: {
		async getInterest() {
			this.interests = [];
			const { data } = await this.$http.get("myinterest");

			this.interests = data.data.data;
		},
		image(stat) {
			if (stat == "chosen") return "/img/win/win.png";
			if (stat == "not selected") return "/img/win/lose.png";
			if (stat == "not yet") return "/img/win/run.png";
		},
	},
};
</script>
